import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { capitalize, get, isEmpty, set } from "lodash";
import { useCallback, useMemo, useRef } from "react";
import withQuery from "with-query";
import tree from "./tree/api.json";
import {
  GlobalApiHook,
  GlobalApiHookOptions,
  GlobalApiRequestPayload,
} from "./api.type";
import { replaceParamsToUrl } from "./utils";
import { getRuntimeEnv } from "../hoc/runtime-env";
import { useDispatch } from "react-redux";
import axios from "axios";

const sliceTrees: any = tree;

const getGroupName = (name: string) => {
  return capitalize(`api_${name}`);
};

const getApiEndpoint = (name: string) => {
  return capitalize(`fetch_${name}`);
};

const prepareHeaders = (headers: any = {}) => {
  const customHeaders: any = getStatefulApiHeaders();

  if (!isEmpty(customHeaders)) {
    Object.keys(customHeaders).forEach((key) => {
      if (headers.set) {
        headers.set(key, customHeaders[key]);
      } else {
        headers[key] = customHeaders[key];
      }
    });
  }

  return headers;
};

const axiosBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api, extraOptions) => {
  const {
    url,
    method,
    params,
    body: data,
    headers = {},
    responseHandler,
  } = args;
  const baseURL = getRuntimeEnv("NEXT_PUBLIC_BASE_API_URL");

  try {
    const result: any = await axios({
      ...args,
      baseURL,
      url: `${baseURL}/${url}`,
      method,
      params,
      data,
      headers: prepareHeaders(headers),
      ...(responseHandler && { responseType: "blob" }),
    });
    if (responseHandler) {
      const data = window.URL.createObjectURL(await result.data);
      return {
        data,
      };
    }
    return { data: result.data };
  } catch (err: any) {
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

// /**
//  * @deprecated
//  */
const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseUrl = getRuntimeEnv("NEXT_PUBLIC_BASE_API_URL");
  const newArgs =
    typeof args === "string"
      ? `${baseUrl}/${args}`
      : { ...args, url: `${baseUrl}/${args.url}` };

  return fetchBaseQuery({ prepareHeaders })(newArgs, api, extraOptions);
};

const generateAllApis = () => {
  const apis: any = {};
  const reducers: any = {};
  const middlewares: any = {};

  Object.keys(sliceTrees).forEach((groupName) => {
    const group = sliceTrees[groupName];

    const api = createApi({
      keepUnusedDataFor: 0,
      reducerPath: getGroupName(groupName),
      baseQuery: axiosBaseQuery,
      endpoints: (builder) => {
        const endpoints: any = {};
        Object.keys(group).forEach((name) => {
          const {
            url,
            method,
            mutable: defaultMutable,
            responseContentType,
          } = group[name];

          const mutable = defaultMutable ?? method !== "GET";
          const func = mutable
            ? builder.mutation<any, any>
            : builder.query<any, any>;
          const responseHandler =
            responseContentType === "file"
              ? async (res: any) => {
                  const data = window.URL.createObjectURL(await res.blob());
                  return {
                    data,
                  };
                }
              : undefined;
          endpoints[getApiEndpoint(name)] = func({
            query: ({ query, params, body, headers, ...others } = {}) => {
              return {
                ...others,
                headers,
                url: withQuery(replaceParamsToUrl(url, params), query),
                method,
                responseHandler,
                ...(mutable && {
                  body,
                }),
              };
            },
          });
        });

        return endpoints;
      },
    });

    apis[api.reducerPath] = api;
    reducers[api.reducerPath] = api.reducer;
    middlewares[api.reducerPath] = api.middleware;
  });

  return { apis, reducers, middlewares };
};

export const { apis, reducers, middlewares } = generateAllApis();

export const useStatefulApi = <B extends any, D extends any, E extends any>(
  path: string,
  options?: GlobalApiHookOptions<B, D>
): GlobalApiHook<B, D, E> => {
  const dispatch = useDispatch();

  const prevPayloadRef = useRef<GlobalApiRequestPayload<B>>();

  const [groupName, action] = useMemo(() => {
    const [name, ...actions] = path.split(".");
    return [name, actions.join(".")];
  }, [path]);
  const api = apis[getGroupName(groupName)];
  if (!api && !options?.optional) {
    const message = `Seem state "${path}" is not defined in slice "state/tree/api.json"`;
    throw new Error(message);
  }

  const mutable = !!api?.[`use${getApiEndpoint(action)}Mutation`];
  const useApi =
    api?.[`use${getApiEndpoint(action)}Mutation`] ??
    api?.[`use${getApiEndpoint(action)}Query`];

  if (!useApi && !options?.optional) {
    const message = `Seem state "${path}" is not defined in slice "state/tree/api.json"`;
    throw new Error(message);
  }

  const result = useApi?.(
    !mutable ? options?.defaultQueryPayload : undefined,
    !mutable
      ? {
          skip: options?.skip,
        }
      : undefined
  );
  const resetState = useCallback(
    () => api?.util && dispatch(api?.util?.resetApiState()),
    [api?.util, dispatch]
  );

  const updateState = useCallback(
    (newState: any) => {
      dispatch(
        api.util.updateQueryData(
          "Fetch_getmany",
          options?.defaultQueryPayload,
          (draft: any) => {
            draft.data.lists = newState;
          }
        )
      );
    },
    [api, dispatch, options?.defaultQueryPayload]
  );

  const updateStateAt = useCallback(
    (data: any, index: number) => {
      dispatch(
        api.util.updateQueryData(
          "Fetch_getmany",
          options?.defaultQueryPayload,
          (draft: any) => {
            draft.data.lists[index] = data;
          }
        )
      );
    },
    [api, dispatch, options?.defaultQueryPayload]
  );

  const request = useCallback(
    (payload?: GlobalApiRequestPayload<B>) => {
      const func = mutable ? result?.[0] : result?.refetch;
      prevPayloadRef.current = payload;
      return func?.(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutable, result?.[0], result?.refetch]
  );

  const retryRequestOnFailed = useCallback(() => {
    return request(prevPayloadRef.current);
  }, [request]);

  if (mutable) {
    return {
      ...(result?.[1] || {}),
      data: result?.[1].data ?? options?.initialData,
      request,
      retryRequestOnFailed,
      resetState,
    };
  }

  return {
    ...(result || {}),
    data: result?.data ?? options?.initialData,
    request,
    retryRequestOnFailed,
    resetState,
    updateState,
    updateStateAt,
  };
};

export const setStatefulApiHeaders = (headers: any) => {
  set(global, "statefulApi.headers", headers);
};

export const getStatefulApiHeaders = () => {
  return get(global, "statefulApi.headers");
};
